import { Provider } from '@angular/core';
import { DomResourceService, LogService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import {
  TOKENS as TOKENS_ANALYTICS,
  TrackerFactory,
  TrackerType,
} from '@trustedshops/tswp-core-analytics';
import { FeatureBookingService, TOKENS as MASTERDATA_TOKEN } from '@trustedshops/tswp-core-masterdata';
import {
  GoogleAnalyticsTrackerFactory,
  HubSpotAnalyticsTrackerFactory
} from '@trustedshops/tswp-core-analytics-implementation';
import { DOCUMENT } from '@angular/common';
import { Environment, ENVIRONMENT } from '../models/environment.type';
import { ExtendedTrackingServices } from './extended-tracking.service';

export const TrackingConfiguration: Provider[] = [
  {
    provide: TOKENS_ANALYTICS.TrackerFactories,
    useFactory: (domResourceService: DomResourceService, document: Document, logService: LogService) =>
      new GoogleAnalyticsTrackerFactory(domResourceService, document, logService),
    deps: [TOKENS_COMMON.DomResourceService, DOCUMENT, TOKENS_COMMON.LogService],
    multi: true
  },
  {
    provide: TOKENS_ANALYTICS.TrackerFactories,
    useFactory: (domResourceService: DomResourceService, document: Document, logService: LogService) =>
      new HubSpotAnalyticsTrackerFactory(domResourceService, document, logService),
    deps: [TOKENS_COMMON.DomResourceService, DOCUMENT, TOKENS_COMMON.LogService],
    multi: true
  },
  {
    provide: TOKENS_ANALYTICS.TrackingService,
    useFactory: (
      logService: LogService,
      trackerFactories: TrackerFactory<TrackerType>[],
      featureBookingService: FeatureBookingService,
      environment: Environment) =>
      new ExtendedTrackingServices(logService, trackerFactories, featureBookingService, environment),
    deps: [
      TOKENS_COMMON.LogService,
      TOKENS_ANALYTICS.TrackerFactories,
      MASTERDATA_TOKEN.FeatureBookingService,
      ENVIRONMENT
    ]
  }
];
