import { LogService } from '@trustedshops/tswp-core-common';
import {
  TrackerFactory,
  TrackingEventInfo
} from '@trustedshops/tswp-core-analytics';
import { FeatureBookingService } from '@trustedshops/tswp-core-masterdata';
import {
  TrackingServiceImpl,
} from '@trustedshops/tswp-core-analytics-implementation';
import { isObject } from '../util/is-object';
import { Environment } from '../models/environment.type';

export class ExtendedTrackingServices extends TrackingServiceImpl {
  private isPaidAccount: boolean | null = null;
  private isAccountFree: boolean | null = null;

  public constructor(
    private readonly logService: LogService,
    private readonly trackerFactories: TrackerFactory<any>[],
    private readonly featureBookingService: FeatureBookingService,
    private readonly environment: Environment,
  ) {
    super(logService, trackerFactories);
    this.initializeAccountStatus();
  }

  public async trackEvent(eventInfo: TrackingEventInfo, trackerIds?: string[]): Promise<void> {
    const extendedEventInfo = this.extendGoogleAnalyticsEventParameters(eventInfo);

    return super.trackEvent(extendedEventInfo, trackerIds);
  }

  private async initializeAccountStatus(): Promise<void> {
    try {
      this.isPaidAccount = await this.featureBookingService.isPaidAccount();
      this.isAccountFree = await this.featureBookingService.isFreeAccount();
    } catch (error) {
      this.isPaidAccount = null;
      this.isAccountFree = null;

      this.logService.debug(
        '@trustedshops/tswp-carrier-b2b:ExtendedTrackingServices',
        'Failed to initialize account status:', error);
    }
  }

  private extendGoogleAnalyticsEventParameters(eventInfo: TrackingEventInfo): TrackingEventInfo {
    const extendedEvent = { ...eventInfo };
    if (isObject(extendedEvent.eventParameters[1])) {
      extendedEvent.eventParameters[1] = {
        ...extendedEvent.eventParameters[1],
        account_type: this.getAccountType(),
      };
    }

    return extendedEvent;
  }

  private getAccountType(): 'free' | 'paid' | 'unknown' {
    if (this.isAccountFree === true) {
      return 'free';
    }

    if (this.isPaidAccount === true) {
      return 'paid';
    }

    return 'unknown';
  }
}
